import React from "react"
import YellowHeading from "../../../components/common/YellowHeading"
import Section from "wildComponents/Section"
import { Link } from "wildComponents/Link"
import {
  Box,
  Button,
  Heading,
  HStack,
  Image,
  Stack,
  VStack,
} from "@chakra-ui/react"

function Signup() {
  return (
    <Section className="light" minH="100vh" my={[0, 0, 40]}>
      <Stack direction={["column", "column", "row"]} spacing={(12, 12, 12)}>
        <VStack spacing={[8, 8, 24]} flex="1">
          <VStack align="flex-start" spacing={12} w="full">
            <Heading flex="1" className="jumbo">
              <YellowHeading>Sign up to</YellowHeading> be a DUQE agent
            </Heading>
            <Heading as="h3" className="thin-h3" color="dark.default">
              Want to become an exclusive DUQE Agent? It's as easy as 1, 2, 3.
            </Heading>
          </VStack>
          <VStack spacing={[8, 8, 24]} w="full">
            <Stack
              direction={["column", "column", "row"]}
              justify="flex-start"
              w="full"
              spacing={[8, 12, 12]}
            >
              <Box
                width={["40px", "40px", "100px"]}
                h={["40px", "40px", "100px"]}
                position="relative"
              >
                <Heading
                  className="jumbo light"
                  sx={{ "&.jumbo.light": { fontWeight: "light" } }}
                  position="absolute"
                  top="0"
                  width="100px"
                  zIndex="1"
                >
                  1
                </Heading>
                <Image
                  src="https://res.cloudinary.com/wild-creative/image/upload/v1653637113/DUQE/Vectors/Motifs_05_h5rmh5.svg"
                  positon="absolute"
                  left="0"
                  bottom="0"
                  transform={[
                    "translateY(10px)",
                    "translateY(10px)",
                    "translateY(30px)",
                  ]}
                  h="100%"
                  w="100%"
                  zIndex="-1"
                />
              </Box>
              <Box
                flexGrow="1"
                h="full"
                transform={[
                  "unset",
                  "unset",
                  "translateX(10px)",
                  "translateX(10px)",
                  "translateX(23px)",
                ]}
              >
                <Heading as="h6" color="brandBlue.default">
                  Step 1 - Register as an agent
                </Heading>
                <Heading as="h4" className="thin">
                  Register by filling out our form or send us a message at{" "}
                  <Link to="mailto:info@duqe.ae">
                    <strong>info@duqe.ae</strong>
                  </Link>
                </Heading>
              </Box>
            </Stack>
            <Stack
              direction={["column", "column", "row"]}
              justify="flex-start"
              w="full"
              spacing={[8, 8, 12]}
            >
              <Box
                width={["40px", "40px", "100px"]}
                h={["50px", "50px", "100px"]}
                position="relative"
              >
                <Heading
                  className="jumbo light"
                  sx={{ "&.jumbo.light": { fontWeight: "light" } }}
                  position="absolute"
                  top="0"
                  w="full"
                  h="full"
                  zIndex="1"
                >
                  2
                </Heading>
                <Image
                  src="https://res.cloudinary.com/wild-creative/image/upload/v1653637113/DUQE/Vectors/Motifs_06_f7pnbb.svg"
                  positon="absolute"
                  left="0"
                  bottom="0"
                  transform={[
                    "translateY(10px)",
                    "translateY(10px)",
                    "translateY(40px)",
                  ]}
                  h="100%"
                  w="100%"
                  zIndex="-1"
                />
              </Box>
              <Box flexGrow="1" h="full">
                <Heading as="h6" color="brandBlue.default">
                  Step 2 - Complete the paperwork
                </Heading>
                <Heading as="h4" className="thin">
                  <strong>Sign an Agent Agreement</strong> with DUQE Free Zone.
                </Heading>
              </Box>
            </Stack>
            <Stack
              direction={["column", "column", "row"]}
              justify="flex-start"
              w="full"
              spacing={[8, 8, 12]}
            >
              <Box
                width={["40px", "40px", "100px"]}
                h={["50px", "50px", "100px"]}
                position="relative"
              >
                <Heading
                  className="jumbo light"
                  sx={{ "&.jumbo.light": { fontWeight: "light" } }}
                  position="absolute"
                  top="0"
                  w="full"
                  h="full"
                  zIndex="1"
                >
                  3
                </Heading>
                <Image
                  src="https://res.cloudinary.com/wild-creative/image/upload/v1653637113/DUQE/Vectors/Motifs_04_mlrmdm.svg"
                  positon="absolute"
                  left="0"
                  bottom="0"
                  transform={[
                    "translateY(10px)",
                    "translateY(10px)",
                    "translateY(30px)",
                  ]}
                  h="100%"
                  w="100%"
                  zIndex="-1"
                />
              </Box>
              <Box flexGrow="1" h="full">
                <Heading as="h6" color="brandBlue.default">
                  Step 3 – Send a copy of your licence
                </Heading>
                <Heading as="h4" className="thin">
                  Send us a copy of your trade licence or any equivalent
                  document.
                </Heading>
              </Box>
            </Stack>
          </VStack>
        </VStack>
        <VStack spacing={12} align="flex-end" flex="1">
          <VStack
            p={[12, 12, "70px"]}
            w={["100%", "100%", "560px"]}
            bg="brandConcrete.default"
            align="flex-start"
            spacing={5}
            rounded="30px"
          >
            <Heading
              as="h4"
              className="normal-case"
              sx={{ "&.normal-case": { textTransform: "unset" } }}
            >
              If you haven’t signed up to be a DUQE Agent yet, now is the time
              to do so! Don’t miss out on commercial incentives exclusively
              available with DUQE.
            </Heading>

            <HStack>
              <Link to="https://duqe.tfaforms.net/4975744">
                <Button size="sm">Agent Signup</Button>
              </Link>
            </HStack>
          </VStack>
        </VStack>
      </Stack>
    </Section>
  )
}

export default Signup
