import React from "react"
import Layout from "wildComponents/Layout"
import { Seo } from "wildComponents/seo/components"
import { graphql } from "gatsby"
import Hero from "../sections/page-specific/agents/Hero"
import Why from "../sections/page-specific/agents/Why"
import Signup from "../sections/page-specific/agents/Signup"

export const Head = ({ location, data, pageContext }) => (
  <Seo ctx={pageContext} data={data} location={location} />
)

function About() {
  return (
    <Layout>
      <Hero />
      <Why />
      <Signup />
    </Layout>
  )
}

export default About

export const pageQuery = graphql`
  query ($id: String!) {
    wpPage(id: { eq: $id }) {
      title
    }
  }
`
